







































































































































































































































// import crypto from 'crypto';
import Vue from 'vue';
// import qs from 'qs';
import xss from 'xss';
// @ts-ignore
import BMap from 'vue-baidu-map/components/map/Map.vue';
// @ts-ignore
import BInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue';
// import _ from 'lodash';
import * as help from './data';
import { baiduKey } from '~/util/common';

export default Vue.extend({
  name: 'About',
  components: {
    BMap,
    BInfoWindow,
  },
  asyncData(ctx) {
    const { id } = ctx.params;
    let isCooperation = false;
    if (ctx.route.path === '/cooperation.html') {
      isCooperation = true;
    }

    /*
    const id = ctx.params.id || helpList.list[0].id;
    const detail = yield this.ctx.service.data.query(version, 'help.getDetail', {helpId: id}, false);
    const md5 = crypto.createHash('md5');
    const timestamp = Date.now();
    const token = md5
      .update(
        'help.getDetail' +
          '1.0' +
          timestamp +
          '89D81FBC94E57BD3AA0F32F4CE078C34'
      )
      .digest('hex');

    let detail = {};
    const detailRes: any = await ctx.$axios.post(
      `/v1/`,
      qs.stringify({
        helpId: id,
        action: 'help.getDetail',
        version: '1.0',
        token,
        timestamp,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      }
    );
    if (detailRes.code === 1) {
      detail = detailRes.data;
    } */

    return {
      // @ts-ignore
      detail: help[`help_${id}`],
      id,
      isCooperation,
    };
  },
  data() {
    return {
      menu: ['关于聚展'],
      center: { lng: 120.010564, lat: 30.298575 }, // 120.010564, 30.298575
      zoom: 15,
      isCooperation: false,
      baiduKey,
    };
  },
  async created() {},
  methods: {
    xss,
    // 控制显示隐藏下拉菜单
    switchMenu(name: never) {
      if (this.menu.length === 0) {
        this.menu.push(name);
      } else {
        const index = this.menu.findIndex((item) => name === item);
        if (index > -1) {
          this.menu.splice(index, 1);
        } else {
          this.menu.push(name);
        }
      }
    },
    setClassName(name: never) {
      const index = this.menu.findIndex((item) => name === item);
      if (index > -1) {
        return 'user_link cont open';
      } else {
        return 'user_link cont';
      }
    },
    setClassNameIcon(name: never) {
      const index = this.menu.findIndex((item) => name === item);
      if (index > -1) {
        return 'el-icon-arrow-up';
      } else {
        return 'el-icon-arrow-down';
      }
    },
  },
  head(): Record<string, any> {
    return {
      title: `广告服务-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
      ],
    };
  },
});
