























































































































































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';
import {
  cabinClassOption,
  defaultAirTicketObj,
  defaultAirTicketSearch,
} from '../helper';
import RefundRule from './RefundRule.vue';
import Baggage from './Baggage.vue';

export default Vue.extend<any, any, any>({
  name: 'ListItem',
  components: {
    RefundRule,
    Baggage,
  },
  props: {
    // searchInfo: {
    //   type: Object,
    //   default: () => JSON.parse(JSON.stringify(defaultAirTicketSearch)),
    // },
    info: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  } as any,
  data(): any {
    return {
      // activeName: 'hot',
      showList: false,
      showStop: false,
      showRefundRule: false,
      showBaggage: false,
      dialogVisible: false,
      loadingTime: false,
    };
  },
  filters: {
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    handleDiscount(e: any) {
      let res = Math.divide(Number(e), 100).toFixed(1);
      let str = Number(res) < 10 ? `${res}折` : '全价';
      return str;
    },
  },
  computed: {
    // 已选的去程航班信息(往返)
    goFlightInfo() {
      let result: any = this.$store.state.airTicket.goFlightInfo;
      return result || JSON.parse(JSON.stringify(defaultAirTicketObj));
    },
  },
  watch: {},
  mounted() {},
  methods: {
    handleTimeout() {
      this.loadingTime = true;
      location.reload();
    },
    refundRuleMouseover(e: any) {
      this.showRefundRule = true;
    },
    refundRuleMouseout() {
      this.showRefundRule = false;
    },
    baggageMouseover(e: any) {
      this.showBaggage = true;
    },
    baggageMouseout() {
      this.showBaggage = false;
    },
    async handleBuy() {
      const token = this.$cookies.get('access_token');
      if (!token) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );

        this.$login.show();
        return;
      }
      // await this.$store.commit('airTicket/setSearchInfo', this.info);
      await this.$store.commit('airTicket/setFlightInfo', { ...this.info });
      await this.$store.commit('airTicket/setCabinInfo', { ...this.item });

      let searchTime = this.$store.state.airTicket.searchTime;
      let currentTime = Date.parse(new Date() as any);
      let differTime = Math.subtract(currentTime, searchTime);

      // 超时20分钟，重新查询  单位:毫秒  1200000 = 1200秒 = 20分钟
      if (differTime > 1200000) {
        // 已超时20分钟，请重新查询
        this.dialogVisible = true;
        return false;
      }

      this.$router.push({
        path: '/airTicket/submitOrder',
      });
    },
  },
});
