






























































































































































































































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';
import {
  cabinClassOption,
  defaultAirTicketObj,
  defaultAirTicketSearch,
} from '../helper';
import CabinItem from './CabinItem.vue';
import RefundRule from './RefundRule.vue';

export default Vue.extend<any, any, any>({
  name: 'ListItem',
  components: {
    CabinItem,
    RefundRule,
  },
  props: {
    // list: {
    //   type: Array,
    //   default: () => [],
    // },
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    // searchName: {
    //   type: String,
    //   default: '',
    // },
    searchInfo: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(defaultAirTicketSearch)),
    },
    info: {
      type: Object,
      default: () => {},
    },
  } as any,
  data(): any {
    return {
      // activeName: 'hot',
      showList: false,
      showStop: false,
    };
  },
  filters: {
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    handleDiscount(e: any) {
      let res = Math.divide(Number(e), 100).toFixed(1);
      let str = Number(res) < 10 ? `${res}折` : '全价';
      return str;
    },
  },
  computed: {
    // 已选的去程航班信息(往返)
    goFlightInfo() {
      let result: any = this.$store.state.airTicket.goFlightInfo;
      return result || JSON.parse(JSON.stringify(defaultAirTicketObj));
    },
  },
  mounted() {},
  methods: {
    async handleToggle() {
      if (this.searchInfo.tripType == 1) {
        this.showList = !this.showList;
      }
      if (this.searchInfo.tripType == 2) {
        await this.$store.commit('airTicket/setGoFlightInfo', { ...this.info });
        // await this.$store.commit('airTicket/setGoCabinInfo', { ...item });
      }
    },
    handleStop() {
      this.showStop = !this.showStop;
    },
  },
});
