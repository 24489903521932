










import Vue from 'vue';
import moment from 'moment';
import _, { isString } from 'lodash';
// import qs from 'qs';
// import { Context } from '@nuxt/types';
// import { ResponseCode } from '~/util/constant';
// import { $cookies } from '~/util/api';
// import {
//   adminHost,
//   getTodayDate,
//   handleNewsUrl,
//   handleNodeServerError,
// } from '~/util/common';
// import GoodsItem from '~/components/Goods/item-seo.vue';
// import { TraceCode } from '~/util/trace';

export default Vue.extend({
  layout: 'no-layout', // 如果你不想在404页面上应用全局布局
  head: {
    title: '404 - 页面未找到',
    meta: [
      {
        name: 'description',
        content: '您访问的页面不存在',
      },
    ],
  },
});
