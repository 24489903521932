























































































































































































































































































































































































































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';
import { defaultAirTicketSearch } from '../helper';

export default Vue.extend<any, any, any>({
  name: 'ListItem',
  components: {},
  props: {
    // list: {
    //   type: Array,
    //   default: () => [],
    // },
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    // searchName: {
    //   type: String,
    //   default: '',
    // },
    // info: {
    //   type: Object,
    //   default: () => {},
    // },
    searchInfo: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(defaultAirTicketSearch)),
    },
    airlineList: {
      type: Array,
      default: () => [],
    }, // 航空公司列表
    departAirportList: {
      type: Array,
      default: () => [],
    }, // 出发机场列表
    arriveAirportList: {
      type: Array,
      default: () => [],
    }, // 到达机场列表
  } as any,
  data(): any {
    return {
      // activeName: 'hot',
      showList: false,
      showStop: false,
      filter_item_airline: false,
      filter_item_time: false,
      filter_item_airport: false,
      filter_item_class_grade: false,
      filter_item_airplane_type: false,
      filter_item_other: false,
      showFilterMore: false,
      airlineSelect: [],
      startTimeSelect: [], // 起飞时间
      startTimeSelectList: [],
      endTimeSelect: [], // 抵达时间
      endTimeSelectList: [],
      departAirportSelect: [],
      arriveAirportSelect: [],
      cabinClassSelect: [],
      flightSizeSelect: [],

      timeList: [
        {
          label: '上午 6~12点',
          startTime: 6,
          endTime: 12,
        },
        {
          label: '下午 12~18点',
          startTime: 12,
          endTime: 18,
        },
        {
          label: '晚上 18~24点',
          startTime: 18,
          endTime: 24,
        },
      ],
      cabinClassList: [
        {
          value: '0',
          label: '头等舱',
        },
        {
          value: '1',
          label: '公务舱',
        },
        {
          value: '2',
          label: '经济舱',
        },
        {
          value: '3',
          label: '超级经济舱',
        },
        // {
        //   value: '4',
        //   label: '豪华头等舱',
        // },
      ],
      sortActive: 1,
      sortPlaceholder: '更多排序',
      sortList: [
        {
          value: 3,
          label: '起飞晚-早',
        },
        {
          value: 4,
          label: '到达早-晚',
        },
        {
          value: 5,
          label: '到达晚-早',
        },
        {
          value: 6,
          label: '耗时短-长',
        },
        {
          value: 7,
          label: '耗时长-短',
        },
        {
          value: 8,
          label: '价格高-低',
        },
      ],
    };
  },
  filters: {
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    handleDiscount(e: any) {
      let res = Math.divide(Number(e), 100).toFixed(1);
      let str = Number(res) < 10 ? `${res}折` : '全价';
      return str;
    },
  },
  computed: {},
  mounted() {
    document.addEventListener('click', this.hideFilterOptions);
  },
  destroyed() {
    document.removeEventListener('click', this.hideFilterOptions);
  },
  methods: {
    handleToggle() {
      this.showList = !this.showList;
    },
    handleStop() {
      this.showStop = !this.showStop;
    },
    /**
     * 点击元素之外隐藏元素
     * @param {any} event:any
     * @returns {any}
     */
    hideFilterOptions(event: any) {
      let isSelf = (document as any)
        .getElementById('filter_item_airline')
        .contains(event.target); // 这个是自己的区域
      if (!isSelf) {
        this.filter_item_airline = false;
      }

      let filter_item_time = (document as any)
        .getElementById('filter_item_time')
        .contains(event.target);
      if (!filter_item_time) {
        this.filter_item_time = false;
      }
      //
      let filter_item_airport = (document as any)
        .getElementById('filter_item_airport')
        .contains(event.target);
      if (!filter_item_airport) {
        this.filter_item_airport = false;
      }
      //
      let filter_item_class_grade = (document as any)
        .getElementById('filter_item_class_grade')
        .contains(event.target);
      if (!filter_item_class_grade) {
        this.filter_item_class_grade = false;
      }
      //
      let filter_item_airplane_type = (document as any)
        .getElementById('filter_item_airplane_type')
        .contains(event.target);
      if (!filter_item_airplane_type) {
        this.filter_item_airplane_type = false;
      }
      //
      let filter_item_other = (document as any)
        .getElementById('filter_item_other')
        ?.contains(event.target);
      if (!filter_item_other) {
        this.filter_item_other = false;
      }
    },
    /**
     * 显示/隐藏下拉选项
     * @returns {any}
     */
    filterKeyClick() {
      this.filter_item_airline = !this.filter_item_airline;
    },
    filterTimeClick() {
      this.filter_item_time = !this.filter_item_time;
    },
    filterItemAirportClick() {
      this.filter_item_airport = !this.filter_item_airport;
    },

    filterCabinClassClick() {
      this.filter_item_class_grade = !this.filter_item_class_grade;
    },
    filterAirplaneTypeClick() {
      this.filter_item_airplane_type = !this.filter_item_airplane_type;
    },
    filterItemOtherClick() {
      this.filter_item_other = !this.filter_item_other;
    },
    airlineCompanyChange() {
      this.confirmFilter();
    },
    startTimeChange(e: any) {
      this.startTimeSelectList = [];
      e.map((i: any) => {
        let res = this.timeList.find((x: any) => x.label == i);
        if (i == res.label) this.startTimeSelectList.push(res);
      });
      this.confirmFilter();
    },
    endTimeChange(e: any) {
      this.endTimeSelectList = [];
      e.map((i: any) => {
        let res = this.timeList.find((x: any) => x.label == i);
        if (i == res.label) this.endTimeSelectList.push(res);
      });

      this.confirmFilter();
    },
    departAirportChange() {
      this.confirmFilter();
    },
    arriveAirportChange() {
      this.confirmFilter();
    },
    cabinClassChange() {
      this.confirmFilter();
    },
    flightSizeChange() {
      this.confirmFilter();
    },
    confirmFilter() {
      this.$emit('on-change', {
        airlineSelect: this.airlineSelect,
        startTimeSelect: this.startTimeSelect, // 起飞时间
        startTimeSelectList: this.startTimeSelectList,
        endTimeSelect: this.endTimeSelect, // 抵达时间
        endTimeSelectList: this.endTimeSelectList,
        departAirportSelect: this.departAirportSelect,
        arriveAirportSelect: this.arriveAirportSelect,
        cabinClassSelect: this.cabinClassSelect,
        flightSizeSelect: this.flightSizeSelect,
        sortActive: this.sortActive,
      });
    },
    filterMoreMouseover() {
      this.showFilterMore = true;
    },
    filterMoreMouseout() {
      this.showFilterMore = false;
    },
    handleSort(e: any) {
      this.sortActive = e.value;
      this.sortPlaceholder = e.label ? e.label : '更多排序';
      // this.confirmFilter();
      this.$emit('on-sort', e.value);
    },
  },
});
