



















































































































































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';
import {
  defaultAirTicketObj,
  defaultAirTicketSearch,
} from '../helper';

export default Vue.extend<any, any, any>({
  name: 'ListItem',
  components: {},
  props: {
    // list: {
    //   type: Array,
    //   default: () => [],
    // },
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    // searchName: {
    //   type: String,
    //   default: '',
    // },
    searchInfo: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(defaultAirTicketSearch)),
    },
    info: {
      type: Object,
      default: () => {},
    },
  } as any,
  data(): any {
    return {};
  },
  filters: {
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    handleDiscount(e: any) {
      let res = Math.divide(Number(e), 100).toFixed(1);
      let str = Number(res) < 10 ? `${res}折` : '全价';
      return str;
    },
  },
  computed: {
    // 已选的去程航班信息(往返)
    goFlightInfo() {
      let result: any = this.$store.state.airTicket.goFlightInfo;
      return result || JSON.parse(JSON.stringify(defaultAirTicketObj));
    },
    // 退票手续费 flightRuleListStr 的值可能为空数组 如:武汉——大连 9月30日
    refundRuleItem() {
      let ruleList = this.info.flightRuleList;
      let result =
        ruleList &&
        ruleList.length > 0 &&
        ruleList[0].hasOwnProperty('refundRuleItem')
          ? ruleList[0]['refundRuleItem']
          : [];
      return result; // this.info.flightRuleList[0]['refundRuleItem'] || [];
    },
    // 同舱改期收费
    changeRuleItem() {
      return this.info.flightRuleList[0]['changeRuleItem'] || [];
    },
  },
  mounted() {},
  methods: {},
});
