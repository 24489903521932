








































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';
import {
  cabinClassOption,
  defaultAirTicketObj,
  defaultAirTicketSearch,
} from '../helper';

export default Vue.extend<any, any, any>({
  name: 'ListItem',
  components: {},
  props: {
    // list: {
    //   type: Array,
    //   default: () => [],
    // },
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
    // searchName: {
    //   type: String,
    //   default: '',
    // },
    searchInfo: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(defaultAirTicketSearch)),
    },
    info: {
      type: Object,
      default: () => {},
    },
  } as any,
  data(): any {
    return {};
  },
  filters: {
    handleBaggageDesc(list: any[]) {
      return list.length > 0 ? list.join(' ') : '';
    },
  },
  computed: {
    // 已选的去程航班信息(往返)
    goFlightInfo() {
      let result: any = this.$store.state.airTicket.goFlightInfo;
      return result || JSON.parse(JSON.stringify(defaultAirTicketObj));
    },
    // 行李规则  flightRuleListStr 的值可能为空数组 如:武汉——大连 9月30日
    baggageItem() {
      let ruleList = this.info.flightRuleList;
      let result =
        ruleList &&
        ruleList.length > 0 &&
        ruleList[0].hasOwnProperty('baggageItem')
          ? ruleList[0]['baggageItem']
          : [];
      return result; //this.info.flightRuleList[0]['baggageItem'] || [];
    },
  },
  mounted() {},
  methods: {},
});
