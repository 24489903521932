












































































































































































































import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
// @ts-ignore
import BMap from 'vue-baidu-map/components/map/Map.vue';

import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { getTime, message } from '~/util/common';
// import { TypeVisa, TypeSetMeal } from './type';
import { randomServiceList } from './service';
import {
  defaultAirTicketSearch,
  defaultAirTicketSearchValue,
  defaultAirTicketObj,
} from './helper';
import { gaodeKey } from '~/util/common';
import { mockAirTicketList } from './mock';
import AirTicketSearch from '~/components/AirTicket/Search.vue';
import ListFilter from './component/ListFilter.vue';
import ListItem from './component/ListItem.vue';

export default Vue.extend<any, any, any>({
  name: 'airTicket',
  components: {
    AirTicketSearch,
    ListFilter,
    ListItem,
  },
  filters: {
    getContentText(val: string): string {
      var reg = /<[^>]+>/gi;
      // 过滤html标签
      let str = val ? val.replace(reg, '') : '';
      return str;
    },
    firstLink(val: string): string {
      const [areaId] = val.split('-').slice(1, 1);

      if (areaId === '0') {
        return '/visa/';
      } else {
        return val;
      }
    },
    handleDate(e: any) {
      return e ? moment(e).format('MM月DD日') : '';
    },
    // 获取周几
    handleWeek(e: any) {
      let num: any = moment(e).format('E');
      var week = ['一', '二', '三', '四', '五', '六', '日'];
      return e ? `周${week[num - 1]}` : '';
    },
    // 获取时分 (08:30)
    getHourMinutes(str: string) {
      return str ? str.slice(11, 16) : '';
    },
  },
  // 校验params参数
  // validate(ctx: Context) {
  //   const { path } = ctx.route;
  //   if (path === '/visa' || path === '/visa/') {
  //     return true;
  //   }
  //   // const _validate = /information-(\d+)-(\d+)-(\d+)/.test(path);
  //   // return _validate;
  // },
  watchQuery(newQuery: any, oldQuery: any) {
    // Only execute component methods if the old query string contained `bar`
    // and the new query string contains `foo`
    return newQuery.depDate;
  },
  async asyncData(ctx) {
    return;
    // const { page = '1', areaId = '' } = ctx.query;
    const {
      // departCity = '',
      // arriveCity = '',
      // type = 0,
      // bizType = 0,
      // page = '1',
    } = ctx.query;

    let searchInfo = {
      // departCity: '',
      // departCityInput: '',
      // arriveCity: '',
      // arriveCityInput: '',
      // depCityCode: '',
      // depDate: '',
      // arrCityCode: '',
      // arrDate: '',
      // cabinClass: 'ALL_CABIN',
      // tripType: 1,
      // needMultiClassPrice: true,
      // transferLeaveDate: '',
      departCity: '北京',
      departCityInput: '北京',
      arriveCity: '上海',
      arriveCityInput: '上海',
      depCityCode: 'BJS',
      depDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      arrCityCode: 'SHA',
      arrDate: '',
      cabinClass: 'ALL_CABIN',
      tripType: 1,
      needMultiClassPrice: true,
      transferLeaveDate: '',
    } as any;
    for (let i in searchInfo) {
      if (ctx.query[i]) searchInfo[i] = ctx.query[i];
    }

    let allAirTicket: any[] = [];
    let airTicketList: any[] = [];

    let airlineCompanyList: string[] = []; // 获取全部航空公司列表
    let departAirportList: string[] = []; // 出发地-机场列表
    let arriveAirportList: string[] = []; // 目的地-机场列表

    // 假数据-----------------------------------------------------------------------------------------------------
    // allAirTicket = mockAirTicketList;
    // allAirTicket.map((item: any) => {
    //   item['flightRuleList'] = JSON.parse(item.flightRuleListStr);
    //   let seconds =
    //     (Date.parse(item.arrDate) - Date.parse(item.depDate)) / 1000;
    //   item['duration_seconds'] = seconds;
    //   let s = getTime(seconds);
    //   item['duration'] = `${s[0] != '00' ? s[0] + '小时' : ''}${
    //     s[1] != '00' ? s[1] + '分' : ''
    //   }`;
    //   if (!airlineCompanyList.some((i) => i == item.airlineName))
    //     airlineCompanyList.push(item.airlineName);
    //   if (!departAirportList.some((i) => i == item.depAirportName))
    //     departAirportList.push(item.depAirportName);
    //   if (!arriveAirportList.some((i) => i == item.arrAirportName))
    //     arriveAirportList.push(item.arrAirportName);
    // });
    // airTicketList = allAirTicket;
    // 假数据-结束-----------------------------------------------------------------------------------------------------

    let params = {
      ...searchInfo,
    };
    delete params.departCityInput;
    delete params.arriveCityInput;

    const result: any[] = await Promise.all([
      ctx.$axios.post('/api/flightOrderApi/searchFlight', params),
    ]);

    const [ariTicketResult] = result;

    if (ariTicketResult.code === '1') {
      allAirTicket = ariTicketResult.data;
      // airTicketList = ariTicketResult.data;

      allAirTicket.map((item: any) => {
        let seconds =
          (Date.parse(item.arrDate) - Date.parse(item.depDate)) / 1000;
        item['flightRuleList'] = JSON.parse(item.flightRuleListStr);
        // item.flightRuleListStr = '';
        item['duration_seconds'] = seconds;
        let s = getTime(seconds);
        item['duration'] = `${s[0] != '00' ? s[0] + '小时' : ''}${
          s[1] != '00' ? s[1] + '分' : ''
        }`;
        if (!airlineCompanyList.some((i) => i == item.airlineName))
          airlineCompanyList.push(item.airlineName);
        if (!departAirportList.some((i) => i == item.depAirportName))
          departAirportList.push(item.depAirportName);
        if (!arriveAirportList.some((i) => i == item.arrAirportName))
          arriveAirportList.push(item.arrAirportName);
        // 舱位列表
        item.flightCabinInfoList.map((cabinItem: any) => {
          // 如果舱位没有退改规则, 则使用上一级机票的退改规则
          cabinItem['flightRuleList'] = cabinItem.flightRuleListStr
            ? JSON.parse(cabinItem.flightRuleListStr)
            : JSON.parse(item.flightRuleListStr);
        });
      });
      airTicketList = JSON.parse(JSON.stringify(allAirTicket));
    }

    return {
      searchInfo,
      allAirTicket,
      airTicketList,
      airlineCompanyList,
      departAirportList,
      arriveAirportList,
    };
  },
  data(): Record<string, any> {
    return {
      randomServiceList,
      pageNum: 1,
      countryList: [],

      dateList: [
        {
          id: 1,
          list: [
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-09',
              week: '周二',
              price: 800,
            },
            {
              time: '08-10',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
          ],
        },
        {
          id: 2,
          list: [
            {
              time: '08-15',
              week: '周二',
              price: 800,
            },
            {
              time: '08-16',
              week: '周二',
              price: 800,
            },
            {
              time: '08-10',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
          ],
        },
        {
          id: 3,
          list: [
            {
              time: '08-22',
              week: '周二',
              price: 800,
            },
            {
              time: '08-23',
              week: '周二',
              price: 800,
            },
            {
              time: '08-24',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
            {
              time: '08-08',
              week: '周二',
              price: 800,
            },
          ],
        },
      ],
      activeDate: '08-09',
      // goFlightInfo: JSON.parse(JSON.stringify(defaultAirTicketObj))
      step: 1,
      backAllAirTicket: [], // 返程机票
      backAirTicketList: [], // 返程机票
      searchInfo: {
        // departCity: '北京',
        // departCityInput: '北京',
        // arriveCity: '上海',
        // arriveCityInput: '上海',
        // depCityCode: 'BJS',
        // depDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        // arrCityCode: 'SHA',
        // arrDate: '',
        // cabinClass: 'ALL_CABIN',
        // tripType: 1,
        // needMultiClassPrice: true,
        // transferLeaveDate: '',
        departCity: '',
        departCityInput: '',
        arriveCity: '',
        arriveCityInput: '',
        depCityCode: '',
        depDate: '',
        arrCityCode: '',
        arrDate: '',
        cabinClass: '',
        tripType: 1,
        needMultiClassPrice: true,
        transferLeaveDate: '',
      },
      allAirTicket: [],
      airTicketList: [],
      airlineCompanyList: [], // 获取全部航空公司列表
      departAirportList: [], // 出发地-机场列表
      arriveAirportList: [], // 目的地-机场列表
      listLoading: false,
    };
  },
  computed: {
    // 已选的去程航班信息(往返)
    goFlightInfo() {
      let result: any = this.$store.state.airTicket.goFlightInfo;
      return result || JSON.parse(JSON.stringify(defaultAirTicketObj));
    },
  },
  watch: {
    // $route: {
    //   handler: (val, oldVal) => {
    //     console.log('561-监听路由', val)
    //   },
    //   deep: true,
    // },
  },
  beforeRouteUpdate(to, from, next) {
    let toQuery = { ...to.query };
    toQuery['departCityInput'] = toQuery.departCity;
    toQuery['arriveCityInput'] = toQuery.arriveCity;
    if (toQuery.hasOwnProperty('departCity')) {
      for (let i in toQuery) {
        if (toQuery[i]) this.searchInfo[i] = toQuery[i];
      }
      // searchInfo.departCity
      this.listLoading = true;
      this.getList(toQuery);
    }
    next();
  },
  created() {
    // this.initSearch();
    let urlParams = this.$route.query;
    for (let i in urlParams) {
      if (urlParams[i]) this.searchInfo[i] = urlParams[i];
    }
    let time = Date.parse(new Date() as any);
    this.$store.commit('airTicket/setSearchTime', time);
    this.$store.commit('airTicket/setSearchInfo', { ...this.searchInfo });

    this.getList();
  },
  mounted() {},
  methods: {
    getList(e: any = null) {
      this.listLoading = true;
      if (e) {
        for (let i in e) {
          this.searchInfo[i] = e[i];
        }
      }

      // 如果"出发地"和"目的地"为空则使用默认城市(场景：点击左侧菜单"机票"进入)
      if (!this.searchInfo.departCity && !this.searchInfo.arriveCity) {
        for (let i in defaultAirTicketSearchValue) {
          this.searchInfo[i] = (defaultAirTicketSearchValue as any)[i];
        }
      } else {
        // for (let i in this.searchInfo) {
        //   if (this.$route.query[i]) this.searchInfo[i] = this.$route.query[i];
        // }
      }

      let params = {
        ...this.searchInfo,
      };
      // let params = { ...defaultAirTicketSearch } as any;
      // if (this.searchInfo.departCity && this.searchInfo.arriveCity) {
      //   params = {
      //     ...this.searchInfo,
      //   };
      // } else {
      //   params = {
      //     ...defaultAirTicketSearchValue,
      //   };
      // }
      // 假数据-----------------------------------------------------------------------------------------------------
      // setTimeout(() => {
      //   this.allAirTicket = mockAirTicketList;
      //   this.allAirTicket.map((item: any) => {
      //     item['flightRuleList'] = JSON.parse(item.flightRuleListStr);
      //     let seconds =
      //       (Date.parse(item.arrDate) - Date.parse(item.depDate)) / 1000;
      //     item['duration_seconds'] = seconds;
      //     let s = getTime(seconds);
      //     item['duration'] = `${s[0] != '00' ? s[0] + '小时' : ''}${
      //       s[1] != '00' ? s[1] + '分' : ''
      //     }`;
      //     if (!this.airlineCompanyList.some((i: any) => i == item.airlineName))
      //       this.airlineCompanyList.push(item.airlineName);
      //     if (
      //       !this.departAirportList.some((i: any) => i == item.depAirportName)
      //     )
      //       this.departAirportList.push(item.depAirportName);
      //     if (
      //       !this.arriveAirportList.some((i: any) => i == item.arrAirportName)
      //     )
      //       this.arriveAirportList.push(item.arrAirportName);
      //   });
      //   this.airTicketList = this.allAirTicket;
      //   this.listLoading = false;
      // }, 3000);
      // return false;
      // 假数据-结束-----------------------------------------------------------------------------------------------------

      delete params.departCityInput;
      delete params.arriveCityInput;

      // const result: any[] = await Promise.all([
      //   this.$axios.post('/api/flightOrderApi/searchFlight', params),
      // ]);
      // const [ariTicketResult] = result;
      this.$axios
        .post('/api/flightOrderApi/searchFlight', params)
        .then((ariTicketResult: any) => {
          this.listLoading = false;
          if (ariTicketResult.code === '1') {
            this.allAirTicket = ariTicketResult.data;

            this.allAirTicket.map((item: any) => {
              let seconds =
                (Date.parse(item.arrDate) - Date.parse(item.depDate)) / 1000;
              item['flightRuleList'] = JSON.parse(item.flightRuleListStr);
              // item.flightRuleListStr = '';
              item['duration_seconds'] = seconds;
              let s = getTime(seconds);
              item['duration'] = `${s[0] != '00' ? s[0] + '小时' : ''}${
                s[1] != '00' ? s[1] + '分' : ''
              }`;
              if (
                !this.airlineCompanyList.some((i: any) => i == item.airlineName)
              )
                this.airlineCompanyList.push(item.airlineName);
              if (
                !this.departAirportList.some(
                  (i: any) => i == item.depAirportName
                )
              )
                this.departAirportList.push(item.depAirportName);
              if (
                !this.arriveAirportList.some(
                  (i: any) => i == item.arrAirportName
                )
              )
                this.arriveAirportList.push(item.arrAirportName);
              // 舱位列表 flightRuleListStr 的值可能为空数组 如:武汉——大连 9月30日
              if (item.flightCabinInfoList) {
                item.flightCabinInfoList.map((cabinItem: any) => {
                  // 如果舱位没有退改规则, 则使用上一级机票的退改规则
                  cabinItem['flightRuleList'] = cabinItem.flightRuleListStr
                    ? JSON.parse(cabinItem.flightRuleListStr)
                    : JSON.parse(item.flightRuleListStr);
                });
              }
            });
            this.airTicketList = JSON.parse(JSON.stringify(this.allAirTicket));
            for (let i in params) {
              if (params[i]) this.searchInfo[i] = params[i];
            }
            // this.$router.replace({
            //   path: '/airTicket/list',
            //   query: {
            //     ...params,
            //   },
            // });
          }
        })
        .catch((err: any) => {
          this.listLoading = false;
        });
      //
    },
    initSearch() {
      // 设置默认值
      // 设置默认出发地-目的地
      if (!this.searchInfo.depCityCode) {
        this.searchInfo.depCityCode = 'BJS';
        this.searchInfo.departCity = '北京';
        this.searchInfo.departCityInput = '北京';
      }
      if (!this.searchInfo.arrCityCode) {
        this.searchInfo.arrCityCode = 'SHA';
        this.searchInfo.arriveCity = '上海';
        this.searchInfo.arriveCityInput = '上海';
      }
      this.searchInfo.cabinClass = 'ALL_CABIN';
      if (!this.searchInfo.depDate) {
        // 默认为明天的日期
        // this.searchInfo.depDate = moment().format('YYYY-MM-DD');
        this.searchInfo.depDate = moment().add(1, 'days').format('YYYY-MM-DD');
      }
    },
    handleQuery(e: any) {
      for (let i in this.searchInfo) {
        if (e[i]) this.searchInfo[i] = e[i];
      }
      if (!this.listLoading) this.getList();
      return false;
      let queryObj = { ...e };
      delete queryObj.departCityInput;
      delete queryObj.arriveCityInput;

      this.$router.replace({
        path: '/airTicket/list',
        query: {
          ...queryObj,
        },
      });
    },
    handleBack(e: number) {
      let result = this.$store.state.airTicket.goFlightInfo;
      if (e === 2 && !result.depAirportName) {
        message.call(this, {
          delay: 3000,
          title: '请先选择去程航班',
          type: 'error',
        });
      }
      if (e === 2 && result.depAirportName) {
        this.step = 2;
        this.handleGetList();
      }
    },
    // 获取返程机票列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      let params = {
        ...this.searchInfo,
      };
      params.departCity = this.searchInfo.arriveCity;
      params.depCityCode = this.searchInfo.arrCityCode;
      params.arriveCity = this.searchInfo.departCity;
      params.arrCityCode = this.searchInfo.depCityCode;
      params.depDate = this.searchInfo.arrDate;

      return false;
      const ticketRes: any = await this.$axios.post(
        '/api/flightOrderApi/searchFlight',
        {
          status: this.ETabStatus[this.activeType],
          pageNum,
          pageSize,
        }
      );

      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticketRes.data.records.map((item: any) => {
          item['visaMainPic'] = item.visaPic ? item.visaPic.split(',')[0] : '';
        });
        this.ticket = ticketRes.data;
      }
    },

    //
    filterChange(e: any) {
      this.handleFilter(e);
    },
    handleFilter(e: any) {
      // 筛选航空公司
      let filterAirline = (str: string) => {
        return e.airlineSelect.length > 0
          ? e.airlineSelect.includes(str)
          : true;
      };
      // 筛选起飞时段
      let handleDepartTime = (str: string) => {
        let h = str ? str.slice(11, 13) : ''; // 获取时间中的"时"
        return e.startTimeSelectList.some(
          (item: any) => Number(h) > item.startTime && Number(h) < item.endTime
        );
      };

      let filterStartTime = (str: string) => {
        return e.startTimeSelect.length > 0 ? handleDepartTime(str) : true;
      };
      // 筛选抵达时段
      let handleArriveTime = (str: string) => {
        let h = str ? str.slice(11, 13) : ''; // 获取时间中的"时"
        return e.endTimeSelectList.some(
          (item: any) => Number(h) > item.startTime && Number(h) < item.endTime
        );
      };
      let filterEndTime = (str: string) => {
        return e.endTimeSelect.length > 0 ? handleArriveTime(str) : true;
      };

      // 筛选出发机场
      let filterDepartAirport = (str: string) => {
        return e.departAirportSelect.length > 0
          ? e.departAirportSelect.includes(str)
          : true;
      };

      // 筛选到达机场
      let filterArriveAirport = (str: string) => {
        return e.arriveAirportSelect.length > 0
          ? e.arriveAirportSelect.includes(str)
          : true;
      };

      // 筛选机型
      let filterFlightSize = (str: string) => {
        return e.flightSizeSelect.length > 0
          ? e.flightSizeSelect.includes(str)
          : true;
      };
      // 筛选条件叠加
      // this.airTicketList = this.allAirTicket.filter(
      //   (item: any) =>
      //     filterAirline(item.airlineName) &&
      //     filterStartTime(item.depDate) &&
      //     filterEndTime(item.arrDate) &&
      //     filterDepartAirport(item.depAirportName) &&
      //     filterArriveAirport(item.arrAirportName) &&
      //     filterFlightSize(item.flightSize)
      // );

      let filterCabin = (item: any) => {
        let newList: any[] = [];
        // 筛选舱位
        item.flightCabinInfoList.map((i: any) => {
          let childFilter =
            e.cabinClassSelect.length > 0
              ? e.cabinClassSelect.includes(i.cabinClass)
              : true;
          if (childFilter) {
            newList.push({ ...i });
          }
        });
        item.flightCabinInfoList = [...newList];
        // return item

        let result = null;
        if (e.cabinClassSelect.length > 0) {
          result = item.flightCabinInfoList.length > 0 ? item : false;
        } else {
          result = item;
        }

        return result;
      };

      let copyList = JSON.parse(JSON.stringify(this.allAirTicket));
      let filterNewList = copyList.filter(
        // this.airTicketList = this.allAirTicket.filter(
        (item: any) =>
          filterAirline(item.airlineName) &&
          filterStartTime(item.depDate) &&
          filterEndTime(item.arrDate) &&
          filterDepartAirport(item.depAirportName) &&
          filterArriveAirport(item.arrAirportName) &&
          filterCabin(item) &&
          filterFlightSize(item.flightSize)
      );
      this.airTicketList = filterNewList;
    },
    sortChange(e: number) {
      let func = null;
      if (e === 1) {
        func = (a: any, b: any) => {
          return Number(a.totalPrice) - Number(b.totalPrice);
        };
      }
      if (e === 2) {
        func = (a: any, b: any) => {
          let depDate_a = Date.parse(a.depDate);
          let depDate_b = Date.parse(b.depDate);
          return Number(depDate_a) - Number(depDate_b);
        };
      }
      if (e === 3) {
        func = (a: any, b: any) => {
          let depDate_a = Date.parse(a.depDate);
          let depDate_b = Date.parse(b.depDate);
          return Number(depDate_b) - Number(depDate_a);
        };
      }
      if (e === 4) {
        func = (a: any, b: any) => {
          let arrDate_a = Date.parse(a.arrDate);
          let arrDate_b = Date.parse(b.arrDate);
          return Number(arrDate_a) - Number(arrDate_b);
        };
      }
      if (e === 5) {
        func = (a: any, b: any) => {
          let arrDate_a = Date.parse(a.arrDate);
          let arrDate_b = Date.parse(b.arrDate);
          return Number(arrDate_b) - Number(arrDate_a);
        };
      }
      if (e === 6) {
        func = (a: any, b: any) => {
          let duration_a =
            Date.parse(a.arrDate) / 1000 - Date.parse(a.depDate) / 1000;
          let duration_b =
            Date.parse(b.arrDate) / 1000 - Date.parse(b.depDate) / 1000;
          return Number(duration_a) - Number(duration_b);
        };
      }
      if (e === 7) {
        func = (a: any, b: any) => {
          let duration_a =
            Date.parse(a.arrDate) / 1000 - Date.parse(a.depDate) / 1000;
          let duration_b =
            Date.parse(b.arrDate) / 1000 - Date.parse(b.depDate) / 1000;

          return Number(duration_b) - Number(duration_a);
        };
      }

      if (e === 8) {
        func = (a: any, b: any) => {
          return Number(b.totalPrice) - Number(a.totalPrice);
        };
      }

      this.airTicketList.sort(func);
    },
    // 翻页
    changePager(page: number) {
      const { visaAreaId = 0, areaId = 0, type = 0, bizType = 0 } = this;

      this.$router.push({
        path: `/visa-${visaAreaId}-${areaId}-${type}-${bizType}-${page}/`,
        query: this.$route.query,
      });
    },
    handlePrev() {
      this.$refs.refDateCarousel.prev();
    },
    handleNext() {
      this.$refs.refDateCarousel.next();
    },
  },
  head() {
    const { pageNum } = this.$data;
    return {
      title: `机票${pageNum > 1 ? `_第${pageNum}页` : ''}-聚展`,
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: `聚展网`,
        },
      ],
      script: [
        {
          type: 'text/javascript',
          src: 'https://webapi.amap.com/maps?v=1.4.15&key=8607adb51cc446875a7a4da2f229da22',
          body: true,
        },
      ],
    };
  },
});
